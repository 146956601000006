<template>
	<div style="overflow-x:hidden;">
		<v-row style="padding:40px">
			<img :src="$store.state.icons.icons['favicon-32x32']" alt />
		</v-row>
		<v-row class="d-flex align-center justify-center" style="height:70vh">
			<v-col class="d-flex align-center justify-center">
				<div>
					<h1>404 Error</h1>
					<p>The resource could not be found.</p>
					<v-btn @click="back" class="my-back-btn" depressed>
						<img :src="$store.state.icons.icons['LeftArrow']" class="back-btn" alt />
						Go to back
					</v-btn>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	methods: {
		back() {
			this.$router.back();
		}
	}
};
</script>